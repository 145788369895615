<template>

  <div v-if="nextEvent" class="events">
    {{nextEvent.name}}<br>
    <p class="datePlace">
      <span class="arrow">→</span>
      {{new Date(nextEvent.datetime).toLocaleDateString('de-DE', {hour: '2-digit', minute:'2-digit'})}} Uhr {{nextEvent.place}}
    </p>
    <div class="description wysiwyg-left">
      <div v-html="nextEvent.description"/>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'Event',
    data: () => ({
      nextEvent:false,
    }),
    async created() {
      let temp = await this.$client.items("events").readMany({ fields: ['*.*'], filter: {datetime: {'_gte': '$NOW'}}, limit: 1, sort:['datetime']})
      this.nextEvent = temp.data[0]
    }
  }
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .events {
    border: solid 3px $purple;
    padding: 40px 0 40px 20px;
    margin:60px 0 60px 0;
    line-height: 90%;
    background: $beige;
    border-radius: 30px;
    font-size: $font-size-2-normal;

    .arrow {
      padding-left: 10px;
      font-size:$font-size-4-normal;
      position:relative;
      top: -0px;
      color: $purple;
    }

    .description {
      padding: 16px 0 0 0;

      font-size: $font-size-1-medium;

      a {
        color: $purple !important;
      }

      p {
        margin-bottom: 0px;
      }
    }
  }

  </style>