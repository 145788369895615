import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false

import Axios from 'axios'
Vue.prototype.$axios = Axios;

import VueLocalStorage from 'vue-localstorage'
import LoadScript from 'vue-plugin-load-script'
Vue.use(VueLocalStorage)
Vue.use(LoadScript)


import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
import 'swiper/css/swiper.css'

import { Directus } from "@directus/sdk";

const client = new Directus("https://directus.neuesamt.org")

Object.defineProperties(Vue.prototype, {
  $client: { value: client }
})

import EventV2 from '@/components/EventV2'
import PeopleV2 from '@/components/PeopleV2'
import CoverageV2 from '@/components/CoverageV2'
import MediaSwiperV2 from '@/components/MediaSwiperV2'
import PanelV2 from '@/components/PanelV2'
import MediaFileV2 from '@/components/MediaFileV2'
import OfferV2 from '@/components/OfferV2'
import SingleOfferV2 from '@/components/SingleOfferV2'
import CooperationsV2 from '@/components/CooperationsV2'

Vue.component('EventV2',EventV2)
Vue.component('PeopleV2',PeopleV2)
Vue.component('CoverageV2',CoverageV2)
Vue.component('MediaSwiperV2',MediaSwiperV2)
Vue.component('PanelV2',PanelV2)
Vue.component('MediaFileV2',MediaFileV2)
Vue.component('OfferV2',OfferV2)
Vue.component('SingleOfferV2',SingleOfferV2)
Vue.component('CooperationsV2',CooperationsV2)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
