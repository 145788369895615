<template>

    <v-tabs v-if="content" class="slider" vertical slider-size="1" slider-color="transparent">
      <v-tab v-for="(cooperation, i) in content" :key="i">
        {{cooperation.cooperations_id.name}}
      </v-tab>
      <v-tab-item v-for="(cooperation, i) in content" :key="i">
        <v-card flat>
          <v-card-text>
            <img v-if="cooperation.cooperations_id && cooperation.cooperations_id.image" :src="imageLink(cooperation.cooperations_id.image,120)" :title="cooperation.cooperations_id.image.title" width="120px" />
          </v-card-text>
          <v-card-text v-html="cooperation.cooperations_id.description" />
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <img v-if="cooperation.cooperations_id && cooperation.cooperations_id.opinion_author_image" :src="imageLink(cooperation.cooperations_id.opinion_author_image, 200)" :title="cooperation.cooperations_id.opinion_author_image.title" width="200px" />
              </v-col>
              <v-col cols="6" v-html="cooperation.cooperations_id.opinion" />
              <v-col cols="12">
                {{cooperation.cooperations_id.opinion_author}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

</template>

<script>

  export default {
    name: 'CooperationsV2',
    props: ['content'],
    data: () => ({
     
    }),
    methods: {
      imageLink: function (file, width, height) {
        if (file) {
          let temp = 'https://directus.neuesamt.org/assets/' + file.id + '?quality=75'

          if (width) temp += '&width=' + width

          if (height) temp += '&height=' + height
          

          return temp
        }

        return null
      }
    }
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .slider {
    
    .v-slide-group__content {
      border-top: solid 1px black;
      background-color: $beige;
      white-space: normal;
    }

    .v-tab {
      font-size: $font-size-1-normal;
      letter-spacing: normal;
      padding: 0 30px 0 0;
      text-transform: none;
      color: black !important;
      justify-content: start;
      border-bottom: solid 1px black;
      text-align: left;
    }


    .v-tab--active {
      font-weight: bold;
    }
    
    .v-tab--active::after {
      content: "→";
      font-size: $font-size-2-normal;
      position: absolute;
      right: 4px;
      top: 2px;
    }

    .v-window-item {
      border-top: solid 1px black;
      border-right: solid 1px black;
      border-bottom: solid 1px black;
    }
  }

</style>