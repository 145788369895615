<template>
  
  <v-card class="card">
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="(offer, i) in content" :key="i" >
        {{ offer.offers_id.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(offer, i) in content" :key="i" >
        <v-card flat>
          <v-card-text>
            <MediaSwiperV2 :content="offer.offers_id.image_files" contentWidth="400"/>
          </v-card-text>
           <v-card-text class="name pb-0">
            {{offer.offers_id.name}}
          </v-card-text>

          <v-card-text class="description" style="height: 160px" v-html="offer.offers_id.description" />

          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="2">
                <div class="arrow" style="float:right;">→</div>
              </v-col>
              <v-col cols="10">
                <div class="text-bold">
                  {{offer.offers_id.cooperative_shares}}<br />
                  {{offer.offers_id.monthly_price}}
                </div>
              </v-col>
              <v-col cols="2">
              </v-col>
              <v-col cols="10">
                <v-btn v-if="offer.offers_id.download_file" outlined :small="$vuetify.breakpoint.smAndDown" rounded class="naa-button-purple" :href="'https://directus.neuesamt.org/assets/' + offer.offers_id.download_file.id" style="width:200px;" target="_blank">Infos <v-icon small style="position: absolute; right:0;">mdi-download</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

  export default {
    name: 'OfferV2',
    props: ['content'],
    data: () => ({
      tab: null
    })
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .offer {
    background-color: $purple;
    color: $beige;
    margin-bottom: 20px;
    
    .card {

      background-color: $beige !important;
      border-radius: 20px;

      .v-tab {
        font-size: $font-size-0-large;
        font-weight: bold;
        text-transform: none;
        color: black;
        padding: 0 16px 4px 0;
        letter-spacing: normal;
      }

      .v-tab--active {
        border: none;
      }

      .v-tabs-slider {
        background-color: black
      }

      .v-card {
        background-color: $beige !important;
      }

      .v-card__text {
        color: black;
      }

      .name {
        font-size: larger;
        font-weight: bolder;
      }
    }
  }

</style>