<template>
  <v-row v-if="content" :class="'people people-' + styleclass">
    <v-col cols="12">
      <swiper :class="'swiper swiper-' + styleclass" :options="swiperOption">
        <swiper-slide v-for="(person, i) in content" :key="i" style="height:auto; padding-bottom: 80px">
          <v-card v-if="person.people_id" class="card" max-width="300" flat tile style="height: 100%; margin: 0 40px 0 40px">
            <v-card-text>
              <v-avatar size="120">
                <img :src="imageLink(person.people_id.image, 120)" :title="person.people_id.image.title" />
              </v-avatar>
            </v-card-text>

            <v-card-text class="name pb-2" style="height:40px">
              {{person.people_id.name}}
            </v-card-text>

            <v-card-text class="mb-6 pa-0" style="height:60px;">
              <v-chip v-for="(tag, z) in person.people_id.tags" :key="z" x-small outlined>{{tag}}</v-chip>
            </v-card-text>
            
            <v-card-text class="description" v-html="person.people_id.description" />

            <v-card-text class="linkList"  style="position: absolute; bottom: 10px; left: 50%; transform: translate(-50%, 0);">
              <ul>
                <li v-for="(link, name) in person.people_id.links" :key="name">
                  <a :href="link" target="_blank">{{name}}</a>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev">&lt;&lt;</div>
        <div class="swiper-pagination" slot="pagination" />
        <div class="swiper-button-next" slot="button-next">&gt;&gt;</div>
      </swiper>
    </v-col>
  </v-row>

</template>

<script>

  export default {
    name: 'PeopleV2',
    props: ['content', 'styleclass'],
    data: () => ({
      swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
    }),
    mounted () {
      this.swiperOption.slidesPerView = this.$vuetify.breakpoint.smAndDown ? 1 : 3;
    },
    methods: {
      imageLink: function (file, width) {
        if (file) {
          let temp = 'https://directus.neuesamt.org/assets/' + file.id + '?quality=75'

          if (width) temp += '&width=' + width
          
          return temp
        }

        return null
      }
    }
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .people {
    margin-bottom: 20px;

    .v-chip {
      margin: 0 4px 4px 0;
      text-transform: uppercase;
    }

    .card {
      padding-bottom: 20px;

      .name {
        margin-bottom:12px;
        padding: 16px 0 0 0;
        font-size: $font-size-1-medium;
        font-family: Amtsschrift2020, cursive, monospace !important;
      }

      .description {
        padding: 16px 0 60px 0;
      }
      
      .linkList {
        padding: 0 10px;
        
        ul {
          padding: 0px;
          
          li {
            margin: 0;
            list-style-type: none;

            a {
              color: black;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .people-beige {
    .text {
      background-color: $beige !important;
      color: black;
    }

    .v-chip {
      border: 2px solid $purple !important;
    }

    .v-card__text {
      color:rgba(0, 0, 0, 1) !important;
    }

    .v-chip {
      color: $purple !important;
    }

    .card {
      background-color: $beige !important;

      .linkList {
        ul {
          li {
            border-bottom: solid 1px black;
          }
        }
      }
    }
  }

  .people-purple {
    .text {
      background-color: $purple !important;
      color: $beige;
    }

    .v-chip {
      border: 2px solid $beige !important;
    }

    .v-card__text {
      color:$beige !important;
    }

    .v-chip {
      color: $beige !important;
      border-color: $beige;
    }

    .card {
      background-color: $purple !important;

      .linkList {
        ul {
          li {
            border-bottom: solid 1px $beige;

            a {
              color: $beige !important;
            }
          }
        }
      }
    }
  }

</style>