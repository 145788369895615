<template>
  
  <v-app-bar app flat class="header" height="120px" style="max-width: 1200px">
      <v-spacer />
      
      <v-toolbar-title>
          <router-link to="/">
            <v-img src="@/assets/NAA-logo.svg" style="width: 80px; height: 80px;" contain />
          </router-link>
      </v-toolbar-title>
      
      <v-spacer />
      
      <v-toolbar-title>
        <v-item-group class="menu" v-show="$vuetify.breakpoint.mdAndUp">
          <v-item><v-btn text to="/">Home</v-btn></v-item>
          <v-item><v-btn text to="/vision">Vision</v-btn></v-item>
          <v-item><v-btn text to="/mitgliedschaft">Mitgliedschaft</v-btn></v-item>
          <v-item><v-btn text to="/community">Community</v-btn></v-item>
          <v-item><v-btn text to="/haus">Haus</v-btn></v-item>
          <v-item><v-btn text to="/popup">Pop-Up &amp; Verein</v-btn></v-item>
          <v-item><v-btn text to="/partner">Förderer &amp; Partner</v-btn></v-item>
        </v-item-group>
      </v-toolbar-title>
      
      <v-spacer />

      <v-toolbar-title v-show="$vuetify.breakpoint.smAndDown">
        <v-app-bar-nav-icon  @click="drawer = true" style="pointer-events: all;" />
      </v-toolbar-title>

      <v-navigation-drawer v-model="drawer" class="mobile-menu" width="100%" app right hide-overlay>
        <v-icon @click="drawer=false">mdi-close</v-icon>

        <v-list style="width: 80%; margin: auto">
          <v-list-item><v-btn plain to="/">Home</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/vision">Vision</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/mitgliedschaft">Mitgliedschaft</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/community">Community</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/haus">Haus</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/popup">Pop-Up &amp; Verein</v-btn></v-list-item>
          <v-divider color="white" />
          <v-list-item><v-btn plain to="/partner">Förderer &amp; Partner</v-btn></v-list-item>
        </v-list>

        <v-row class="social" justify="center">
          <v-col cols="3">

            <v-btn rounded outlined :small="$vuetify.breakpoint.smAndDown" href="https://www.instagram.com/neuesamt" target="_blank">Instagram</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn rounded outlined :small="$vuetify.breakpoint.smAndDown" href="https://twitter.com/neuesamt" target="_blank">Twitter</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn rounded outlined :small="$vuetify.breakpoint.smAndDown" href="https://www.facebook.com/neuesamt" target="_blank">Facebook</v-btn>
          </v-col>
          <v-col cols="12" class="neuesamt">@NEuEsAmT</v-col>
        </v-row>
      </v-navigation-drawer>

  </v-app-bar>

</template>

<script>
  export default {
    name: 'HeaderV2',
    data: () => ({
      group: null,
      drawer: false
    })
  }
</script>

<style lang="scss">
  @import "@/assets/_variables.scss";
    
  .header {
    border: none !important;
    background-color: $beige !important;
    margin: auto;

    .menu {
      
      margin: auto;

      .v-btn {
        text-transform: none;
        font-weight:bold;
      }

      .v-btn:before {
        background-color: transparent !important;
      }

      .v-btn--active {
        text-decoration: underline;
        text-decoration-thickness: 0.15rem;
        text-decoration-color: $purple;
      }
    }
  }

  .mobile-menu {

    .v-navigation-drawer__content {
      background-color: $purple;

      .v-icon {
        float:right;
        margin-right: 20px;
        color: $beige;
      }

      .v-btn {
        color: $beige;
        font-size: $font-size-1-small;
        margin: 20px auto 20px auto;
      }

      .v-btn__content {
        opacity: 1 !important;
      }
    }

    .social {

      .col {
        text-align: center;
      }

      .v-btn {
        color: $beige;
        font-size: $font-size-0-medium;
      }

      .neuesamt {
        text-align:center;
        color: $beige;
        margin: auto;
        font-size: $font-size-2-medium;
        font-family: Amtsschrift2020, cursive, monospace !important;
      }
    }
  }
</style>