<template>
  <div v-if="file">
    <video v-if="file.type == 'video/mp4' || file.type == 'video/webm'" preload="none" :poster="mediaLink(poster)" :width="displayWidth" class="video" controls>
      <source :src="'https://directus.neuesamt.org/assets/' + file.id" :title="file.title" :type="file.type">
    </video>
    <v-img v-else :src="mediaLink(file)" :title="file.title" contain :width="displayWidth" style="margin:auto;"/>
  </div>
</template>

<script>
  export default {
    name: 'MediaFileV2',
    props: {'file':Object, 
            'poster': Object,
            'contentWidth': {
                              type: [Number, String],
                              default: '100%'
                            }},
    data: () => ({
      displayWidth: 0
    }),
    methods: {
      mediaLink: function (file) {
        if (!file) return null

        let downloadWidth = 0

        if (this.contentWidth > this.$vuetify.breakpoint.width) {
          downloadWidth = this.$vuetify.breakpoint.width
          this.displayWidth = '100%'
        }
        else {
          downloadWidth = this.contentWidth
          this.displayWidth = this.contentWidth
        }

        return 'https://directus.neuesamt.org/assets/' + file.id + '?width=' + downloadWidth
      }
    }
  }
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

</style>