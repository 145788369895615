import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeV2 from '../views/HomeV2.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeV2,
    alias: '/home'
  },
  {
    path: '/vision',
    name: 'Vision',
    component: () => import(/* webpackChunkName: "about" */ '../views/VisionV2.vue')
  },
  {
    path: '/popup',
    name: 'PopUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/PopupV2.vue')
  },
  {
    path: '/mitgliedschaft',
    name: 'Mitgliedschaft',
    component: () => import(/* webpackChunkName: "about" */ '../views/MitgliedschaftV2.vue')
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import(/* webpackChunkName: "about" */ '../views/CommunityV2.vue')
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import(/* webpackChunkName: "about" */ '../views/PartnerV2.vue')
  },
  {
    path: '/haus',
    name: 'Haus',
    component: () => import(/* webpackChunkName: "about" */ '../views/HausV2.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQV2.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "about" */ '../views/KontaktV2.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImpressumV2.vue')
  },
  {
    path: '/presse',
    redirect: { path: '/home', hash: '#artikel'}
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve ({ selector: to.hash, behavior: 'smooth', });
        }, 1000);
    })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
