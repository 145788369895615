<template>
  <v-footer padless class="footer">
    <v-row>
      <v-col cols="12">
        
        <v-container class="component-container-purple py-12 newsletter">
          <v-row justify="center" align="center">
            <v-col cols="8" sm="4" style="text-align:center;">
              Sei ganz <span class="naa">NaA</span> dran mit unserem Newsletter<br />
              <v-btn rounded class="naa-button-beige" :small="$vuetify.breakpoint.smAndDown" @click.stop="dialog = true" >Anmelden <span class="arrow">→</span></v-btn>
            </v-col>
          </v-row>
        </v-container>
      
      </v-col>
      <v-col cols="12">
      
        <v-container class="component-container">
          <v-row justify="space-around" align="center">
            <v-col cols="12" sm="6" class="contact" style="padding: 40px 20px 40px 40px">
              <img src="@/assets/NAA-logo-horizontal.svg" style="max-width: 240px;"/>
              <br />
              Neues Amt Altona eG<br />
              ‎℅ Altes Finanzamt Altona<br />
              <br />
              Große Bergstraße 264-266<br />
              22767 Hamburg<br />
              <br />
              www.neuesamt.org<br />
              info@neuesamt.org<br />
              040-22863623<br />
            </v-col>
            <v-col cols="12" sm="6" class="aktenschrank">
              <span style="font-weight:bold; font-size: large">Aktenschrank / Downloads:</span>
              <ul>
                <li v-for="(entry, i) in downloads" :key="i">
                  <v-btn outlined rounded block :small="$vuetify.breakpoint.smAndDown" class="naa-button-purple" :href="'https://directus.neuesamt.org/assets/' + entry.file.id" target="_blank">{{entry.name}} <v-icon small style="position: absolute; right:0;">mdi-download</v-icon></v-btn>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      
      </v-col>
      <v-col cols="12">

        <v-container class="component-container-purple social">
          <v-container class="component-container">
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn rounded class="naa-button-beige" :small="$vuetify.breakpoint.smAndDown" style="width: 39%" to="/faq">FAQ<span class="arrow">→</span></v-btn>
                <v-btn rounded class="naa-button-beige" :small="$vuetify.breakpoint.smAndDown" style="width: 39%" to="/kontakt">Kontakt<span class="arrow">→</span></v-btn>
                <v-btn rounded class="naa-button-beige" :small="$vuetify.breakpoint.smAndDown" style="width: 80%" to="/impressum">Datenschutz / Impressum<span class="arrow">→</span></v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <v-btn icon href="https://www.instagram.com/neuesamt" target="_blank"><v-icon>mdi-instagram</v-icon></v-btn>
                    <v-btn icon href="https://twitter.com/neuesamt" target="_blank"><v-icon>mdi-twitter</v-icon></v-btn>
                    <v-btn icon href="https://www.facebook.com/neuesamt" target="_blank"><v-icon>mdi-facebook</v-icon></v-btn>
                    <v-btn icon href="https://www.linkedin.com/company/neues-amt/" target="_blank"><v-icon>mdi-linkedin</v-icon></v-btn>
                    <span class="neuesamt">@Neuesamt</span>
                  </v-col>
                  <v-col cols="12">
                    Vorstand: Robert Beddies, Feena Fensky, Christina Veldhoen, Cornelius Voss<br />
                    Aufsichtsrat: Marco Maas, Julian Meisen, Melanie Obrist, Antonio Petersen, Torsten Rieckmann
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-container>

      </v-col>
    </v-row>
    
    <v-dialog v-model="dialog" max-width="420" >
      <v-card>
        <form action="https://neuesamt.us18.list-manage.com/subscribe/post?u=1bec784a33eb0e7a972d171bf&amp;id=f4ed639e4f" method="post">
          <v-card-title class="headline">Hier zum Newsletter anmelden:</v-card-title>
          <v-card-text>
            <v-text-field outlined rounded dense placeholder="Deine eMail ... ->" label="E-Mail" name="EMAIL"  type="email"/>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_1bec784a33eb0e7a972d171bf_f4ed639e4f" tabindex="-1" value=""></div>
            <v-checkbox v-model="checkbox" :rules="[v => !!v || 'Ohne Bestätigung geht es nicht weiter']" label="„Ja, ich möchte den Newsletter der Neues Amt Altona eG abonnieren!” Ihre Einwilligung können Sie jederzeit widerrufen. Nach Absenden dieses Formulars erhalten Sie eine Bestätigungsmail, bitte prüfen Sie ggf. auch Ihren SPAM-Ordner." required />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn outlined :small="$vuetify.breakpoint.smAndDown" rounded type="submit" @click="dialog = false" :disabled="!checkbox">Abonnieren <span style="padding-left: 10px;font-size:30px; position:relative; top: -3px">→</span></v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <cookie-law v-on:accept="initPixel()" theme="blood-orange" buttonText="Akzeptieren und weiter">
      <div slot="message">
        Diese Website verwendet Cookies und Tracking durch Drittanbieter, mehr Infos erhalten Sie in unserer <router-link to="Impressum">Datenschutzerklärung</router-link>
      </div>
    </cookie-law>
  </v-footer>
</template>

<script>

  import CookieLaw from 'vue-cookie-law'

  export default {
    name: 'Footer',
    components: { 
      CookieLaw
    },
    data: () => ({
      downloads: [],
      dialog: false,
      checkbox: false
    }),
    methods: {
      initPixel: function() {
        /*eslint-disable */
        // facebook init
        if(typeof fbq === 'undefined') {
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/de_DE/fbevents.js');

          fbq('init', '230431711586502'); 
        }
        
        // facebook track
        fbq('track', 'PageView');

        //google
        if(typeof gtag === 'undefined') {

          this.$loadScript("https://www.googletagmanager.com/gtag/js?id=G-L35D1EZYKR").then(() => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-L35D1EZYKR');
          })
        }

        /*eslint-enable */
      }
    },
    mounted() {
      if (this.$localStorage.get('cookie:accepted', false) ) {
        this.initPixel()
      }
    },
    async created() {
      let temp = await this.$client.items("downloads").readMany({ fields: ['*.*.*'], sort:['sort'], filter: {status: {'_eq': 'published'}}})
      this.downloads = temp.data
    }
  }
</script>

<style lang="scss">
  @import "@/assets/_variables.scss";
  
  .footer {
    background-color: $beige !important;

    .newsletter {
      margin-top: 0px;
      font-size: $font-size-2-normal;
      background-color: $purple;
      color:$beige;
      line-height: normal;

      .naa {
        font-size: $font-size-2-normal;
        font-family: Amtsschrift2020, cursive, monospace !important;
      }
    }

    .aktenschrank {
      background-color: $beige;

      ul {
        
        margin: 30px 0 0 0;
        list-style-type: none;
        width:70%;
        padding: 0;

        li {
          margin: 14px 0 14px 0;
        }
      }
    }

    .contact {

    }

    .social {
      padding: 40px 0 40px 0;
      
      .neuesamt {
        margin-left: 20px;
        font-size: $font-size-1-small;
        font-family: Amtsschrift2020, cursive, monospace !important;
      }

      .v-icon {
        color: $beige;
        padding: 6px
      }
    }
  }
</style>