<template>
  <div class="home">

    <v-container class="component-container">
      <v-row v-if="home.coworking_text">
        <v-col cols="12" sm="6" class="wysiwyg">
          <div v-html="home.coworking_text" />
        </v-col>
        <v-col cols="12" sm="6">
         <MediaFileV2 :file="home.coworking_file" :poster="home.coworking_file_poster" contentWidth="426"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container">
      <EventV2 />
    </v-container>

    <v-container class="component-container">
      <v-row v-if="home.moin_left_text">
        <v-col cols="12" class="naa-headline">Moin</v-col>
        <v-col cols="12" sm="6" class="wysiwyg-left">
          <div v-html="home.moin_left_text" />
        </v-col>
        <v-col cols="12" sm="6" class="wysiwyg">
          <div v-html="home.moin_right_text" />
        </v-col>
        <v-col cols="12">
          <MediaSwiperV2 :content="home.moin_files" contentWidth="876"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="home.numbers" class="stats pb-4 component-container">
      <span class="stats-headline">Das Neue Amt Altona auf <br /> einen Blick</span>
      <v-row no-gutters>
        <v-col cols="6" sm="3" v-for="(entry, i) in home.numbers" :key="i">
          <span class="numbers">{{entry.value}}</span><br />
          <span class="arrow">↓</span>
          <p>{{entry.name}}</p>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container">
      <v-row v-if="home.stadt_text">
        <v-col cols="12">
          <MediaFileV2 :file="home.stadt_file" contentWidth="876"/>
        </v-col>
        <v-col cols="12" class="wysiwyg">
          <div v-html="home.stadt_text" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container">
      <v-row>
        <v-col cols="12" class="amtsschrift amtsschrift-larger">
          lerne uns kennen:
        </v-col>
      </v-row>
      <PeopleV2 :content="home.staff" styleclass="beige"/>
    </v-container>

    <v-container class="component-container" id="artikel">
      <CoverageV2 :content="home.coverage" />
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    home: {}
  }),
  async created() {
    let temp = await this.$client.items("home").readMany({ fields: ['*.*','coverage.coverage_id.*.*','moin_files.directus_files_id.*','staff.people_id.*.*']})
    this.home = temp.data
  }
}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .naa-headline {
    font-weight: lighter;
    font-size: $font-size-5-normal !important;
    font-family: Amtsschrift2020, cursive, monospace !important;
  }

  .stats {

    .stats-headline {
      color: $purple;
      font-weight: bolder;
      font-size: $font-size-2-normal;
    }

    .numbers {
      font-size:$font-size-4-normal;
      font-family: Amtsschrift2020, cursive, monospace !important;
    }

    @media screen and (max-width : 959px) {
      .numbers {
            font-size:$font-size-3-normal;
            font-family: Amtsschrift2020, cursive, monospace !important;
          }    
    }
  
    p {
      font-size: $font-size-1-medium;
    }

  }

</style>