<template>
  <v-row v-if="content" class="coverage" justify="space-around" align="center">
    <v-col cols="12">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(coverage, i) in content" :key="i" style="height:auto; padding-bottom: 60px">
          <v-card class="card" max-width="300" style="height: 100%; padding:20px; margin: 0 40px 0 40px">

            <v-img :src="'https://directus.neuesamt.org/assets/' + coverage.coverage_id.image.id" contain style="height:20px;" />

            <v-card-text class="description" v-html="coverage.coverage_id.description" />
            
            <v-card-actions style="position: absolute; bottom: 10px; left: 50%; transform: translate(-50%, 0);">
              <v-btn outlined rounded :small="$vuetify.breakpoint.smAndDown" :href="coverage.coverage_id.link" target="_blank">Ansehen <span style="padding-left: 10px;font-size:30px; position:relative; top: -3px">→</span></v-btn>
            </v-card-actions>
          </v-card>
        </swiper-slide>
        
        <div class="swiper-button-prev" slot="button-prev">&lt;&lt;</div>
        <div class="swiper-pagination" slot="pagination" />
        <div class="swiper-button-next" slot="button-next">&gt;&gt;</div>
      </swiper>
    </v-col>
  </v-row>

</template>

<script>
  export default {
    name: 'CoverageV2',
    props: ['content'],
    data: () => ({
      swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
    }),
    mounted () {
      this.swiperOption.slidesPerView = this.$vuetify.breakpoint.smAndDown ? 1 : 3;
    }
  }
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .coverage {

    margin-bottom: 20px;

    .card {
      border-radius: 40px;
      background-color:$purple;
      
      .description {
        padding: 30px 12px 30px 12px;
        color: #faf3e1;
        font-style: italic;
      }

      .v-btn {
        color: $beige
      }
    }
  }

</style>