<template>

  <v-expansion-panels accordion tile flat class="panel">
    <v-expansion-panel v-for="(item,i) in content" :key="i">
      <v-expansion-panel-header expand-icon="mdi-plus">{{item.headline}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <span v-html="item.text" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>
  export default {
    name: 'PanelV2',
    props: ['content'],
    data: () => ({
    })
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .panel {

    .v-expansion-panel-header {
      font-size: $font-size-1-small;
    }

    .v-expansion-panel {
      background-color: $beige !important;
      border-bottom: solid 1px black;
    }
    .v-expansion-panel:first-child {
      border-top: solid 1px black;
    }

    .v-expansion-panel--active {
      background-color:  white !important;
    }
  }

</style>