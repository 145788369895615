<template>
  <div v-if="content && swiperActive">
    <swiper class="swiper" :options="swiperOption" :instanceName="'swiper-'+_uid">
      <swiper-slide v-for="(medium, i) in content" :key="i">
        <div  style="margin: 0 40px 0 40px">
          <video v-if="medium.directus_files_id.type == 'video/mp4' || medium.directus_files_id.type == 'video/webm'" preload="none" :width="displayWidth" class="video" controls>
            <source :src="'https://directus.neuesamt.org/assets/' + medium.directus_files_id.id" :type="medium.type">
          </video>
          <v-img v-if="medium.directus_files_id.type == 'image/png' || medium.directus_files_id.type == 'image/jpeg'" :width="displayWidth" :src="mediaLink(medium.directus_files_id)" :title="medium.directus_files_id.title" contain/>
        </div>
      </swiper-slide>
      <div v-if="content.length > 1" :id="'button-prev-' + _uid" class="swiper-button-prev" slot="button-prev">&lt;&lt;</div>
      <div v-if="content.length > 1" :id="'pagination-' + _uid" class="swiper-pagination" slot="pagination" />
      <div v-if="content.length > 1" :id="'button-next-' + _uid" class="swiper-button-next" slot="button-next">&gt;&gt;</div>
    </swiper>
  </div>
</template>

<script>
  export default {
    name: 'MediaSwiperV2',
    props: {'content':Array,
            'contentWidth': {
                              type: [Number, String],
                              default: '100%'
                            }},
    data: () => ({
      displayWidth: 0,
      swiperActive: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          clickable: true
        },
        navigation: {}
      }
    }),
    methods: {
      mediaLink: function (file) {
        if (!file) return null

        let downloadWidth = 0

        if (this.contentWidth > this.$vuetify.breakpoint.width) {
          downloadWidth = this.$vuetify.breakpoint.width
          this.displayWidth = '100%'
        }
        else {
          downloadWidth = this.contentWidth
          this.displayWidth = this.contentWidth
        }

        return 'https://directus.neuesamt.org/assets/' + file.id + '?width=' + downloadWidth
      }
    },
    mounted () {

      this.swiperOption.pagination.el = '#pagination-' + this._uid
      this.swiperOption.navigation.nextEl = '#button-next-' + this._uid
      this.swiperOption.navigation.prevEl = '#button-prev-' + this._uid

      this.swiperActive = true

    },
  }
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";
 
</style>