<template>
  <v-app id="app" style="background-color: #faf3e1;">

    <Header />
    
    <v-main>    
      <router-view />
    </v-main>

    <Footer />

  </v-app>
</template>
<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    }
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

 .v-btn {
   text-transform: none;
 }

  .arrow {
    color: $purple;
    font-size: $font-size-3-normal;
  }

  .bigarrow {
    font-size: $font-size-4-normal;
    line-height: 1em;
  }

</style>