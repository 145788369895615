<template>
  
  <v-card class="card" height="100%">
    <v-card flat>
      <v-card-text class="pa-4">
        <MediaFileV2 :file="content.offers_id.image_files[0].directus_files_id" contentWidth="240"/>
      </v-card-text>
      <v-card-text class="name pb-0" style="height: 60px">
        {{content.offers_id.name}}
      </v-card-text>

      <v-card-text class="description" style="height: 200px" v-html="content.offers_id.description" />

      <v-card-actions style="height: 100px;">
        <v-row justify="space-around"  >
          <v-col cols="2">
            <div class="arrow" style="position:relative; top:-30px">→</div>
          </v-col>
          <v-col cols="8">
            <div class="text-bold">
              {{content.offers_id.cooperative_shares}}<br />
              {{content.offers_id.monthly_price}}
            </div>
          </v-col>
          <v-col cols="2">
          </v-col>
          <!--
          <v-col cols="10">
            <v-btn v-if="content.offers_id.download_file" outlined :small="$vuetify.breakpoint.smAndDown" rounded class="naa-button-purple" :href="'https://directus.neuesamt.org/assets/' + content.offers_id.download_file.id" style="width:200px;" target="_blank">Infos <v-icon small style="position: absolute; right:0;">mdi-download</v-icon></v-btn>
          </v-col>
          -->
        </v-row>
        </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>

  export default {
    name: 'SingleOfferV2',
    props: ['content'],
    data: () => ({
    
    })
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .offer {
    background-color: $purple;
    color: $beige;
    margin-bottom: 20px;
    
    .card {

      background-color: $beige !important;
      border-radius: 20px;

      .v-card {
        background-color: $beige !important;
      }

      .v-card__text {
        color: black;
      }

      .name {
        font-size: larger;
        font-weight: bolder;
      }
    }
  }

</style>